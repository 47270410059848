<template>
  <div>
    <b-row class="mt-2">
      <b-col>
        <span>PUBLIC RECORDS</span>
      </b-col>
      <b-col>
        <span>DATE</span>
      </b-col>
      <b-col>
        <span>COURT CASE #</span>
      </b-col>
      <b-col class="text-center">
        <span>Trans<span style="color: #4d917a">Union</span></span>
      </b-col>
      <b-col class="text-center">
        <span style="color: #0566b7; font-weight: bold">Experian</span>
      </b-col>
      <b-col class="text-center">
        <span style="color: #822a3a; font-weight: bold">EQUIFAX</span>
      </b-col>
      <b-col v-if="type === 1">
        <span class="d-flex justify-content-between">
          <span>REASON</span>
          <feather-icon
            icon="PlusCircleIcon"
            size="20"
            class="text-primary cursor-pointer"
            @click="addAccountCreditor(2)"
          />
        </span>
      </b-col>
      <b-col v-if="type === 1">
        <span class="d-flex justify-content-between">
          <span>INSTRUCTION</span>
          <feather-icon
            icon="PlusCircleIcon"
            size="20"
            class="text-primary cursor-pointer"
            @click="addAccountCreditor(3)"
          />
        </span>
      </b-col>
    </b-row>
    <validation-observer ref="form">
      <b-row>
        <b-col
          v-for="(item, index) in counts"
          :key="index"
          cols="12"
          class="mt-1"
        >
          <b-row>
            <b-col>
              <b-form-input v-model="item.publicRecord" />
            </b-col>
            <b-col>
              <b-form-datepicker
                v-model="item.date"
                :placeholder="type == 1 ? '' : 'No date selected'"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-col>
            <b-col>
              <b-form-input v-model="item.courtCase" />
            </b-col>
            <b-col class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                :name="`Trans union check ${index}`"
                :rules="
                  (item.publicRecord !== '' ||
                    item.courtCase != '' ||
                    item.date != '') &&
                  item.expeCheck === false &&
                  item.equiCheck === false
                    ? 'truthy'
                    : null
                "
              >
                <b-form-checkbox
                  v-model="item.tranCheck"
                  class="check-bigger d-flex justify-content-center"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-col>
            <b-col class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                :name="`Experian check ${index}`"
                :rules="
                  (item.publicRecord !== '' ||
                    item.courtCase != '' ||
                    item.date != '') &&
                  item.tranCheck === false &&
                  item.equiCheck === false
                    ? 'truthy'
                    : null
                "
              >
                <b-form-checkbox
                  v-model="item.expeCheck"
                  class="check-bigger d-flex justify-content-center"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-col>
            <b-col class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                :name="`Equifax check ${index}`"
                :rules="
                  (item.publicRecord !== '' ||
                    item.courtCase != '' ||
                    item.date != '') &&
                  item.expeCheck === false &&
                  item.tranCheck === false
                    ? 'truthy'
                    : null
                "
              >
                <b-form-checkbox
                  v-model="item.equiCheck"
                  class="check-bigger d-flex justify-content-center"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-col>
            <b-col v-if="type === 1">
              <b-form-select
                v-model="item.reason"
                :options="reasonsLocal"
                text-field="value"
                value-field="id"
              />
            </b-col>
            <b-col v-if="type === 1">
              <b-form-select
                v-model="item.instruction"
                :options="instructionsLocal"
                text-field="value"
                value-field="id"
              />
            </b-col>
          </b-row>
          <b-row v-if="type !== 1">
            <b-col />
            <b-col />
            <b-col />
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                :name="`Union status ${index}`"
                :rules="item.tranCheck === true ? 'required' : null"
              >
                <b-form-select
                  v-model="item.statusTrUnion"
                  :options="statusesLocal"
                  text-field="value"
                  value-field="id"
                />
                <span v-if="errors[0]" class="text-danger">
                  Status {{ errors[0] }}</span
                >
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                :name="`Experian status ${index}`"
                :rules="item.expeCheck === true ? 'required' : null"
              >
                <b-form-select
                  v-model="item.statusExperian"
                  :options="statusesLocal"
                  text-field="value"
                  value-field="id"
                />
                <span v-if="errors[0]" class="text-danger">
                  Status {{ errors[0] }}</span
                >
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                :name="`Equifax status ${index}`"
                :rules="item.equiCheck === true ? 'required' : null"
              >
                <b-form-select
                  v-model="item.statusEquifax"
                  :options="statusesLocal"
                  text-field="value"
                  value-field="id"
                />
                <span v-if="errors[0]" class="text-danger">
                  Status {{ errors[0] }}</span
                >
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2" />
            <b-col cols="2" />
            <b-col cols="2" />
            <b-col>
              <span
                v-if="
                  (item.publicRecord !== '' ||
                    item.courtCase !== '' ||
                    item.date !== '') &&
                  item.tranCheck === false &&
                  item.equiCheck === false &&
                  item.expeCheck === false
                "
                class="text-warning"
                >* Select at least one</span
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
    <modal-add-account-creditor
      v-if="modalCreditorController"
      :typeinsert="typeInsert"
      @close="closeModalCreditorController"
      @reload="reloadEmit"
    />
  </div>
</template>

<script>
import ModalAddAccountCreditor from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalAddAccountCreditor.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapActions } from "vuex";

export default {
  components: {
    ModalAddAccountCreditor,
  },
  props: {
    type: null,
    idround: null,
    clientAccount: null,
    statuses: null,
    reasons: null,
    instructions: null,
    userId: null,
  },
  data() {
    return {
      typeInsert: "",
      modalCreditorController: false,
      counts: [],
      reasonsLocal: [],
      instructionsLocal: [],
      statusesLocal: [],
      dataToSend: [],
    };
  },
  created() {
    this.statusesLocal = [...this.statuses];
    this.statusesLocal.unshift({ value: "Choose Status", id: null });
    this.reasonsLocal = [...this.reasons];
    this.instructionsLocal = [...this.instructions];
    for (let i = 0; i < 7; i++) {
      this.counts.push({
        publicRecord: "",
        date: "",
        courtCase: "",
        tranCheck: false,
        expeCheck: false,
        equiCheck: false,
        statusTrUnion: null,
        statusExperian: null,
        statusEquifax: null,
        reason: null,
        instruction: null,
      });
    }
  },
  methods: {
    ...mapActions({
      updateRefreshReport: "ReportStore/A_REFRESH_REPORT",
    }),

    addAccountCreditor(type) {
      this.typeInsert = type;
      this.modalCreditorController = true;
    },
    reloadEmit(type) {
      this.$emit("reload", type);
    },
    closeModalCreditorController() {
      this.modalCreditorController = false;
    },
    async saveData() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          this.counts.forEach((count) => {
            if (
              count.date !== "" ||
              count.publicRecord !== "" ||
              count.courtCase !== "" ||
              count.tranCheck === true ||
              count.expeCheck === true ||
              count.equiCheck === true
            ) {
              this.dataToSend.push({
                date: count.date,
                element: [
                  {
                    publicr: count.publicRecord,
                    court: count.courtCase,
                    status: this.type === 1 ? 1 : count.statusTrUnion,
                    bureau_id: 1,
                    value: count.tranCheck === true ? 1 : 0,
                    reason: count.reason,
                    instruc: count.instruction,
                  },
                  {
                    content: count.publicRecord,
                    court: count.courtCase,
                    status: this.type === 1 ? 1 : count.statusExperian,
                    bureau_id: 2,
                    value: count.expeCheck === true ? 1 : 0,
                    reason: count.reason,
                    instruc: count.instruction,
                  },
                  {
                    content: count.publicRecord,
                    court: count.courtCase,
                    status: this.type === 1 ? 1 : count.statusEquifax,
                    bureau_id: 3,
                    value: count.equiCheck === true ? 1 : 0,
                    reason: count.reason,
                    instruc: count.instruction,
                  },
                ],
              });
            }
          });
          if (this.dataToSend.length === 0) {
            this.showWarningSwal("Very important!", "Add Public Records");
          } else {
            const confirm = await this.showConfirmSwal();
            if (confirm.isConfirmed) {
              this.addPreloader();
              const data = await ClientsOptionsServices.deleteAccountsPr({
                iduser: this.userId,
                account: this.clientAccount,
                idround: this.idround,
                dt: this.dataToSend,
              });
              if (data.status === 200) {
                this.$emit("close");
                // this.$emit('reloadT', 3)
                this.updateRefreshReport(3);
                this.showSuccessSwal();
              }
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>


