<template>
  <div>
    <hr class="hr-b" />
    <validation-observer ref="form">
      <div v-for="(item, index) in counts" :key="index">
        <b-row class="mt-2">
          <b-col cols="1" class="text-center">
            <span>Trans<span style="color: #4d917a">Union</span></span>
          </b-col>
          <b-col cols="1" class="text-center">
            <span style="color: #0566b7" class="font-weight-bolder"
              >Experian</span
            >
          </b-col>
          <b-col cols="1" class="text-center">
            <span style="color: #822a3a" class="font-weight-bolder"
              >EQUIFAX</span
            >
          </b-col>
          <b-col>
            <span>MODE</span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="1" class="d-flex justify-content-center">
            <validation-provider
              v-slot="{ errors }"
              :name="`Trans union check ${index}`"
              :rules="
                item.expeCheck === false &&
                item.equiCheck === false &&
                index === 0
                  ? 'truthy'
                  : null
              "
            >
              <b-form-checkbox
                v-model="item.tranCheck"
                class="check-bigger d-flex justify-content-center"
                :state="errors[0] ? false : null"
              />
            </validation-provider>
          </b-col>
          <b-col cols="1" class="d-flex justify-content-center">
            <validation-provider
              v-slot="{ errors }"
              :name="`Experian check ${index}`"
              :rules="
                item.tranCheck === false &&
                item.equiCheck === false &&
                index === 0
                  ? 'truthy'
                  : null
              "
            >
              <b-form-checkbox
                v-model="item.expeCheck"
                class="check-bigger d-flex justify-content-center"
                :state="errors[0] ? false : null"
              />
            </validation-provider>
          </b-col>
          <b-col cols="1" class="d-flex justify-content-center">
            <validation-provider
              v-slot="{ errors }"
              :name="`Equifax check ${index}`"
              :rules="
                item.expeCheck === false &&
                item.tranCheck === false &&
                index === 0
                  ? 'truthy'
                  : null
              "
            >
              <b-form-checkbox
                v-model="item.equiCheck"
                class="check-bigger d-flex justify-content-center"
                :state="errors[0] ? false : null"
              />
            </validation-provider>
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-radio v-model="item.modeRadio" :value="1">
                Same for all bureaus
              </b-form-radio>
              <b-form-radio
                v-model="item.modeRadio"
                style="margin-top: 0.3rem"
                :value="2"
              >
                Different for each bureau
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <span
              v-if="
                item.expeCheck === false &&
                item.tranCheck === false &&
                item.equiCheck === false &&
                result === false &&
                index === 0
              "
              class="text-danger"
              >*Select at least one</span
            >
          </b-col>
        </b-row>
        <b-row
          v-if="
            item.modeRadio === 1 ||
            (item.modeRadio === 2 &&
              (item.tranCheck === true ||
                item.expeCheck === true ||
                item.equiCheck === true))
          "
          class="mt-1"
        >
          <b-col class="d-flex justify-content-between align-items-center">
            <span>ACCOUNT TYPE</span>
            <feather-icon
              icon="PlusCircleIcon"
              class="text-primary cursor-pointer"
              size="15"
              @click="addAccountCreditor(4)"
            />
          </b-col>
          <b-col v-if="type !== 1">
            <span>STATUS</span>
          </b-col>
          <b-col class="d-flex justify-content-between align-items-center">
            <span>ACCOUNT NAME</span>
            <feather-icon
              icon="PlusCircleIcon"
              class="text-primary cursor-pointer"
              size="15"
              @click="addAccountCreditor(1)"
            />
          </b-col>
          <b-col>
            <span>ACCOUNT NUMBER</span>
          </b-col>
          <b-col>
            <span>BALANCE</span>
          </b-col>
          <b-col v-if="type === 1">
            <span class="d-flex justify-content-between">
              <span>REASON</span>
              <feather-icon
                icon="PlusCircleIcon"
                size="15"
                class="text-primary cursor-pointer"
                @click="addAccountCreditor(2)"
              />
            </span>
          </b-col>
          <b-col v-if="type === 1">
            <span class="d-flex justify-content-between">
              <span>INSTRUCTION</span>
              <feather-icon
                icon="PlusCircleIcon"
                size="20"
                class="text-primary cursor-pointer"
                @click="addAccountCreditor(3)"
              />
            </span>
          </b-col>
          <b-col class="d-flex justify-content-between align-items-center">
            <span>PAYMENT STATUS</span>
            <feather-icon
              size="15"
              icon="PlusCircleIcon"
              class="text-primary cursor-pointer"
              @click="addAccountCreditor(5)"
            />
          </b-col>
          <b-col>
            <span>CREDIT LIMIT</span>
          </b-col>
          <b-col>
            <span>MONTHLY PAYMENT</span>
          </b-col>
        </b-row>
        <b-row v-if="item.modeRadio === 1" class="mt-1">
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              :name="`Type gen ${index}`"
              :rules="
                item.expeCheck === true ||
                item.equiCheck === true ||
                item.tranCheck === true
                  ? 'required'
                  : null
              "
            >
              <b-form-select
                v-model="item.accTypeSelect"
                :options="accounts"
                text-field="value"
                value-field="id"
              />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col v-if="type !== 1">
            <validation-provider
              v-slot="{ errors }"
              :name="`Status gen ${index}`"
              :rules="
                item.expeCheck === true ||
                item.equiCheck === true ||
                item.tranCheck === true
                  ? 'required'
                  : null
              "
            >
              <b-form-select
                v-if="type !== 1"
                v-model="item.statusSelect"
                :options="statusesLocal"
                text-field="value"
                value-field="id"
              />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              :name="`Acc name gen ${index}`"
              :rules="
                item.expeCheck === true ||
                item.equiCheck === true ||
                item.tranCheck === true
                  ? 'required'
                  : null
              "
            >
              <vue-autosuggest
                v-model="item.accNameShow"
                :suggestions="optionsToSuggestGen"
                :get-suggestion-value="getSuggestionValue"
                :input-props="{
                  id: 'name-account-general',
                  class: 'form-control',
                  placeholder: 'Select',
                }"
                @input="searchOnInput(item.accNameShow, 1)"
                @selected="selectHandler($event, item, 'accName')"
              >
                <template v-slot="{ suggestion }">
                  <span class="my-suggestion-item">
                    {{ suggestion.item.name }}
                  </span>
                </template>
              </vue-autosuggest>
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              :name="`Acc number gen ${index}`"
              :rules="
                item.expeCheck === true ||
                item.equiCheck === true ||
                item.tranCheck === true
                  ? 'required'
                  : null
              "
            >
              <b-form-input v-model="item.accNumber" />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col>
            <!-- <validation-provider
              v-slot="{ errors }"
              :name="`Balance gen ${index}`"
              :rules="
                item.expeCheck === true ||
                item.equiCheck === true ||
                item.tranCheck === true
                  ? 'validate-amount'
                  : null
              "
            > -->
            <money
              v-model="item.balanceMoney"
              class="form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              }"
            />
            <!-- <span v-if="errors[0]" class="text-danger"> {{ errors[0] }}</span>
            </validation-provider> -->
          </b-col>
          <b-col v-if="type == 1">
            <b-form-select
              v-model="item.reason"
              :options="reasonsLocal"
              text-field="value"
              value-field="id"
            />
          </b-col>
          <b-col v-if="type == 1">
            <b-form-select
              v-model="item.instruction"
              :options="instructionsLocal"
              text-field="value"
              value-field="id"
            />
          </b-col>
          <b-col>
            <b-form-select
              v-model="item.paymentStatSelect"
              :options="paymentsStatusFrom"
              text-field="name"
              value-field="id"
            />
          </b-col>
          <b-col>
            <money
              v-model="item.creditMoney"
              class="form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              }"
            />
          </b-col>
          <b-col>
            <money
              v-model="item.monthlyPayMoney"
              class="form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              }"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="item.modeRadio === 2 && item.tranCheck === true"
          class="mt-1"
        >
          <b-col>
            <span>Transunion</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Tr type account ${index}`"
              :rules="item.tranCheck === true ? 'required' : null"
            >
              <b-form-select
                v-model="item.accTypeSelectTran"
                :options="accounts"
                text-field="value"
                value-field="id"
              />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col v-if="type !== 1">
            <span>Transunion</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Tr status ${index}`"
              :rules="item.tranCheck === true ? 'required' : null"
            >
              <b-form-select
                v-if="type !== 1"
                v-model="item.statusSelectTran"
                :options="statusesLocal"
                text-field="value"
                value-field="id"
              />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <!--        aqui se hace el buscar-->
          <b-col>
            <span>Transunion</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Tr acc name ${index}`"
              :rules="item.tranCheck === true ? 'required' : null"
            >
              <vue-autosuggest
                v-model="item.accNameTranShow"
                :suggestions="optionsToSuggestTran"
                :get-suggestion-value="getSuggestionValue"
                :input-props="{
                  id: 'name-account-trans',
                  class: 'form-control',
                  placeholder: 'Select',
                }"
                @input="searchOnInput(item.accNameTranShow, 2)"
                @selected="selectHandler($event, item, 'accNameTran')"
              >
                <template v-slot="{ suggestion }">
                  <span class="my-suggestion-item">
                    {{ suggestion.item.name }}
                  </span>
                </template>
              </vue-autosuggest>
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col>
            <span>Transunion</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Acc Number tr ${index}`"
              :rules="item.tranCheck === true ? 'required' : null"
            >
              <b-form-input v-model="item.accNumberTran" />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col>
            <span>Transunion</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Balance tran ${index}`"
              :rules="item.tranCheck === true ? 'validate-amount' : null"
            >
              <money
                v-model="item.balanceMoneyTran"
                class="form-control"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$',
                  precision: 2,
                  masked: false,
                }"
              />
              <span v-if="errors[0]" class="text-danger"> {{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col v-if="type == 1">
            <span>Transunion</span>
            <b-form-select
              v-model="item.reasonTran"
              :options="reasonsLocal"
              text-field="value"
              value-field="id"
            />
          </b-col>
          <b-col v-if="type == 1">
            <span>Transunion</span>
            <b-form-select
              v-model="item.instructionTran"
              :options="instructionsLocal"
              text-field="value"
              value-field="id"
            />
          </b-col>
          <b-col>
            <span>Transunion</span>
            <b-form-select
              v-model="item.paymentStatSelectTran"
              :options="paymentsStatusFrom"
              text-field="name"
              value-field="id"
            />
          </b-col>
          <b-col>
            <span>Transunion</span>
            <money
              v-model="item.creditMoneyTran"
              class="form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              }"
            />
          </b-col>
          <b-col>
            <span>Transunion</span>
            <money
              v-model="item.monthlyPayMoneyTran"
              class="form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              }"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="item.modeRadio === 2 && item.expeCheck === true"
          class="mt-1"
        >
          <b-col>
            <span>Experian</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Experian type ${index}`"
              :rules="item.expeCheck === true ? 'required' : null"
            >
              <b-form-select
                v-model="item.accTypeSelectExpe"
                :options="accounts"
                text-field="value"
                value-field="id"
              />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col v-if="type !== 1">
            <span>Experian</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Exp status ${index}`"
              :rules="item.expeCheck === true ? 'required' : null"
            >
              <b-form-select
                v-if="type !== 1"
                v-model="item.statusSelectExpe"
                :options="statusesLocal"
                text-field="value"
                value-field="id"
              />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <!--        aqui se hace el buscar-->
          <b-col>
            <span>Experian</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Experian acc name ${index}`"
              :rules="item.expeCheck === true ? 'required' : null"
            >
              <vue-autosuggest
                v-model="item.accNameExpeShow"
                :suggestions="optionsToSuggestExp"
                :get-suggestion-value="getSuggestionValue"
                :input-props="{
                  id: 'name-account-experian',
                  class: 'form-control',
                  placeholder: 'Select',
                }"
                @input="searchOnInput(item.accNameExpeShow, 3)"
                @selected="selectHandler($event, item, 'accNameExpe')"
              >
                <template v-slot="{ suggestion }">
                  <span class="my-suggestion-item">
                    {{ suggestion.item.name }}
                  </span>
                </template>
              </vue-autosuggest>
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col>
            <span>Experian</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Experian acc number ${index}`"
              :rules="item.expeCheck === true ? 'required' : null"
            >
              <b-form-input v-model="item.accNumberExpe" />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col>
            <span>Experian</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Balance exp ${index}`"
              :rules="item.expeCheck === true ? 'validate-amount' : null"
            >
              <money
                v-model="item.balanceMoneyExpe"
                class="form-control"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$',
                  precision: 2,
                  masked: false,
                }"
              />
              <span v-if="errors[0]" class="text-danger"> {{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col v-if="type == 1">
            <span>Experian</span>
            <b-form-select
              v-model="item.reasonExpe"
              :options="reasonsLocal"
              text-field="value"
              value-field="id"
            />
          </b-col>
          <b-col v-if="type == 1">
            <span>Experian</span>
            <b-form-select
              v-model="item.instructionExpe"
              :options="instructionsLocal"
              text-field="value"
              value-field="id"
            />
          </b-col>
          <b-col>
            <span>Experian</span>
            <b-form-select
              v-model="item.paymentStatSelectExpe"
              :options="paymentsStatusFrom"
              text-field="name"
              value-field="id"
            />
          </b-col>
          <b-col>
            <span>Experian</span>
            <money
              v-model="item.creditMoneyExpe"
              class="form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              }"
            />
          </b-col>
          <b-col>
            <span>Experian</span>
            <money
              v-model="item.monthlyPayMoneyExpe"
              class="form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              }"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="item.modeRadio === 2 && item.equiCheck === true"
          class="mt-1"
        >
          <b-col>
            <span>Equifax</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Equifax type ${index}`"
              :rules="item.equiCheck === true ? 'required' : null"
            >
              <b-form-select
                v-model="item.accTypeSelectEqui"
                :options="accounts"
                text-field="value"
                value-field="id"
              />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col v-if="type !== 1">
            <span>Equifax</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Equi status ${index}`"
              :rules="item.equiCheck === true ? 'required' : null"
            >
              <b-form-select
                v-if="type !== 1"
                v-model="item.statusSelectEqui"
                :options="statusesLocal"
                text-field="value"
                value-field="id"
              />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <!--        aqui se hace el buscar-->
          <b-col>
            <span>Equifax</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Equifax acc name ${index}`"
              :rules="item.equiCheck === true ? 'required' : null"
            >
              <vue-autosuggest
                v-model="item.accNameEquiShow"
                :suggestions="optionsToSuggestEqui"
                :get-suggestion-value="getSuggestionValue"
                :input-props="{
                  id: 'name-account-equifax',
                  class: 'form-control',
                  placeholder: 'Select',
                }"
                @input="searchOnInput(item.accNameEquiShow, 4)"
                @selected="selectHandler($event, item, 'accNameEqui')"
              >
                <template v-slot="{ suggestion }">
                  <span class="my-suggestion-item">
                    {{ suggestion.item.name }}
                  </span>
                </template>
              </vue-autosuggest>
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col>
            <span>Equifax</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Equifax acc number ${index}`"
              :rules="item.equiCheck === true ? 'required' : null"
            >
              <b-form-input v-model="item.accNumberEqui" />
              <span v-if="errors[0]" class="text-danger">
                This field {{ errors[0] }}</span
              >
            </validation-provider>
          </b-col>
          <b-col>
            <span>Equifax</span>
            <validation-provider
              v-slot="{ errors }"
              :name="`Balance equi ${index}`"
              :rules="item.tranCheck === true ? 'validate-amount' : null"
            >
              <money
                v-model="item.balanceMoneyEqui"
                class="form-control"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$',
                  precision: 2,
                  masked: false,
                }"
              />
              <span v-if="errors[0]" class="text-danger"> {{ errors[0] }}</span>
            </validation-provider>
          </b-col>
          <b-col v-if="type == 1">
            <span>Equifax</span>
            <b-form-select
              v-model="item.reasonEqui"
              :options="reasonsLocal"
              text-field="value"
              value-field="id"
            />
          </b-col>
          <b-col v-if="type == 1">
            <span>Equifax</span>
            <b-form-select
              v-model="item.instructionEqui"
              :options="instructionsLocal"
              text-field="value"
              value-field="id"
            />
          </b-col>
          <b-col>
            <span>Equifax</span>
            <b-form-select
              v-model="item.paymentStatSelectEqui"
              :options="paymentsStatusFrom"
              text-field="name"
              value-field="id"
            />
          </b-col>
          <b-col>
            <span>Equifax</span>
            <money
              v-model="item.creditMoneyEqui"
              class="form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              }"
            />
          </b-col>
          <b-col>
            <span>Equifax</span>
            <money
              v-model="item.monthlyPayMoneyEqui"
              class="form-control"
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              }"
            />
          </b-col>
        </b-row>
        <hr class="hr-b" />
      </div>
    </validation-observer>
    <modal-add-account-creditor
      v-if="modalCreditorController"
      :typeinsert="typeInsert"
      @close="closeModalCreditorController"
      @reload="reloadEmit"
    />
  </div>
</template>

<script>
import ModalAddAccountCreditor from "@/views/commons/components/clients/dashboard/options/report-module/modals/ModalAddAccountCreditor.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { VueAutosuggest } from "vue-autosuggest";
import { mapActions } from "vuex";

export default {
  components: {
    ModalAddAccountCreditor,
    VueAutosuggest,
  },
  props: {
    type: null,
    idround: null,
    clientAccount: null,
    statuses: null,
    reasons: null,
    instructions: null,
    userId: null,
    accounts: null,
    paymentsStatusFrom: null,
  },
  data() {
    return {
      typeInsert: "",
      counts: [],
      statusesLocal: [],
      reasonsLocal: [],
      instructionsLocal: [],
      accountsLocal: [],
      dataToSend: [],
      paymentsStatus: [],
      optionsToSuggestGen: [],
      optionsToSuggestTran: [],
      optionsToSuggestExp: [],
      optionsToSuggestEqui: [],
      modalCreditorController: false,
      result: true,
      moneyValidator: false,
      deleteAccountsPi: [],
      deleteAccountsIn: [],
      deleteAccountsPr: [],
      deleteAccountsAc: [],
    };
  },
  async created() {
    this.statusesLocal = [...this.statuses];
    this.statusesLocal.unshift({ value: "Choose Status", id: null });
    this.reasonsLocal = [...this.reasons];
    this.instructionsLocal = [...this.instructions];
    this.accountsLocal = [...this.accounts];
    this.paymentsStatus = [...this.paymentsStatusFrom];
    for (let i = 0; i < 2; i++) {
      this.counts.push({
        tranCheck: false,
        expeCheck: false,
        equiCheck: false,
        modeRadio: 1,
        accTypeSelect: null,
        statusSelect: null,
        accName: "",
        accNameShow: "",
        accNumber: "",
        balanceMoney: 0,
        reason: null,
        instruction: null,
        paymentStatSelect: 0,
        creditMoney: 0,
        monthlyPayMoney: 0,
        accTypeSelectTran: null,
        statusSelectTran: null,
        accNameTran: "",
        accNameTranShow: "",
        accNumberTran: "",
        balanceMoneyTran: 0,
        reasonTran: null,
        instructionTran: null,
        paymentStatSelectTran: 0,
        creditMoneyTran: 0,
        monthlyPayMoneyTran: 0,
        accTypeSelectExpe: null,
        statusSelectExpe: null,
        accNameExpe: "",
        accNameExpeShow: "",
        accNumberExpe: "",
        balanceMoneyExpe: 0,
        reasonExpe: null,
        instructionExpe: null,
        paymentStatSelectExpe: 0,
        creditMoneyExpe: 0,
        monthlyPayMoneyExpe: 0,
        accTypeSelectEqui: null,
        statusSelectEqui: null,
        accNameEqui: "",
        accNameEquiShow: "",
        accNumberEqui: "",
        balanceMoneyEqui: 0,
        reasonEqui: null,
        instructionEqui: null,
        paymentStatSelectEqui: 0,
        creditMoneyEqui: 0,
        monthlyPayMoneyEqui: 0,
      });
    }
  },
  methods: {
        ...mapActions({
    updateRefreshReport:("ReportStore/A_REFRESH_REPORT")
    }),
    addAccountCreditor(type) {
      this.typeInsert = type;
      this.modalCreditorController = true;
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    closeModalCreditorController() {
      this.modalCreditorController = false;
    },
    async searchOnInput(text, type) {
      try {
        if (text === "" || text === undefined) {
          return;
        }
        const data = await ClientsOptionsServices.searchAccountsCreditors({
          q: text,
        });
        if (data.status === 200) {
          if (type === 1) {
            this.optionsToSuggestGen = [{ data: [...data.data] }];
          } else if (type === 2) {
            this.optionsToSuggestTran = [{ data: [...data.data] }];
          } else if (type === 3) {
            this.optionsToSuggestExp = [{ data: [...data.data] }];
          } else if (type === 4) {
            this.optionsToSuggestEqui = [{ data: [...data.data] }];
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    selectHandler(value, item, text) {
      item[`${text}`] = value.item.id;
      item[`${text}Show`] = value.item.name;
    },
    reloadEmit(type) {
      this.$emit("reload", type);
    },
    async saveData() {
      try {
        this.dataToSend = [];
        this.moneyValidator = true;
        this.result = await this.$refs.form.validate();
        let errorPresent = false;

        if (this.result) {
          this.counts.forEach((count) => {
            if (count.modeRadio === 2) {
              if (count.tranCheck === true && count.accNameTran === "") {
                this.showWarningSwal(
                  "Very important!",
                  "Add a valid account name for transunion"
                );
                errorPresent = true;
              }
              if (count.expeCheck === true && count.accNameExpe === "") {
                this.showWarningSwal(
                  "Very important!",
                  "Add a valid account name for transunion"
                );
                errorPresent = true;
              }
              if (count.equiCheck === true && count.accNameEqui === "") {
                this.showWarningSwal(
                  "Very important!",
                  "Add a valid account name for transunion"
                );
                errorPresent = true;
              }
            }
            if (count.modeRadio === 1 && count.accName === "")
              errorPresent = true;
            if (
              count.modeRadio !== "" &&
              errorPresent === false &&
              (count.tranCheck === true ||
                count.expeCheck === true ||
                count.equiCheck === true)
            ) {
              this.dataToSend.push({
                mode: count.modeRadio,
                element: [
                  {
                    status:
                      this.type === 1
                        ? 1
                        : this.type === 0 && count.modeRadio === 1
                        ? count.statusSelect.toString()
                        : count.statusSelectTran.toString(),
                    bureau_id: 1,
                    value: count.tranCheck === true ? 1 : 0,
                    account_name:
                      count.modeRadio === 1
                        ? count.accName.toString()
                        : count.accNameTran.toString(),
                    account_number:
                      count.modeRadio === 1
                        ? count.accNumber.toString()
                        : count.accNumberTran.toString(),
                    balance:
                      count.modeRadio === 1
                        ? count.balanceMoney
                        : count.balanceMoneyTran,
                    type:
                      count.modeRadio === 1
                        ? count.accTypeSelect.toString()
                        : count.accTypeSelectTran.toString(),
                    reason:
                      count.modeRadio === 1 ? count.reason : count.reasonTran,
                    instru:
                      count.modeRadio === 1
                        ? count.instruction
                        : count.instructionTran,
                    payment:
                      count.modeRadio === 1
                        ? count.paymentStatSelect.toString()
                        : count.paymentStatSelectTran.toString(),
                    credit:
                      count.modeRadio === 1
                        ? count.creditMoney.toString()
                        : count.creditMoneyTran.toString(),
                    monthly:
                      count.modeRadio === 1
                        ? count.monthlyPayMoney.toString()
                        : count.monthlyPayMoneyTran.toString(),
                  },
                  {
                    status:
                      this.type === 1
                        ? 1
                        : this.type === 0 && count.modeRadio === 1
                        ? count.statusSelect.toString()
                        : count.statusSelectExpe.toString(),
                    bureau_id: 2,
                    value: count.expeCheck === true ? 1 : 0,
                    account_name:
                      count.modeRadio === 1
                        ? count.accName.toString()
                        : count.accNameExpe.toString(),
                    account_number:
                      count.modeRadio === 1
                        ? count.accNumber.toString()
                        : count.accNumberExpe.toString(),
                    balance:
                      count.modeRadio === 1
                        ? count.balanceMoney
                        : count.balanceMoneyExpe,
                    type:
                      count.modeRadio === 1
                        ? count.accTypeSelect.toString()
                        : count.accTypeSelectExpe.toString(),
                    reason:
                      count.modeRadio === 1 ? count.reason : count.reasonExpe,
                    instru:
                      count.modeRadio === 1
                        ? count.instruction
                        : count.instructionExpe,
                    payment:
                      count.modeRadio === 1
                        ? count.paymentStatSelect.toString()
                        : count.paymentStatSelectExpe.toString(),
                    credit:
                      count.modeRadio === 1
                        ? count.creditMoney.toString()
                        : count.creditMoneyExpe.toString(),
                    monthly:
                      count.modeRadio === 1
                        ? count.monthlyPayMoney.toString()
                        : count.monthlyPayMoneyExpe.toString(),
                  },
                  {
                    status:
                      this.type === 1
                        ? 1
                        : this.type === 0 && count.modeRadio === 1
                        ? count.statusSelect.toString()
                        : count.statusSelectEqui.toString(),
                    bureau_id: 3,
                    value: count.equiCheck === true ? 1 : 0,
                    account_name:
                      count.modeRadio === 1
                        ? count.accName.toString()
                        : count.accNameEqui.toString(),
                    account_number:
                      count.modeRadio === 1
                        ? count.accNumber.toString()
                        : count.accNumberEqui.toString(),
                    balance:
                      count.modeRadio === 1
                        ? count.balanceMoney
                        : count.balanceMoneyEqui,
                    type:
                      count.modeRadio === 1
                        ? count.accTypeSelect.toString()
                        : count.accTypeSelectEqui.toString(),
                    reason:
                      count.modeRadio === 1 ? count.reason : count.reasonEqui,
                    instru:
                      count.modeRadio === 1
                        ? count.instruction
                        : count.instructionEqui,
                    payment:
                      count.modeRadio === 1
                        ? count.paymentStatSelect.toString()
                        : count.paymentStatSelectEqui.toString(),
                    credit:
                      count.modeRadio === 1
                        ? count.creditMoney.toString()
                        : count.creditMoneyEqui.toString(),
                    monthly:
                      count.modeRadio === 1
                        ? count.monthlyPayMoney.toString()
                        : count.monthlyPayMoneyEqui.toString(),
                  },
                ],
              });
              errorPresent = false;
            }
          });
          if (this.dataToSend.length === 0) {
            if (errorPresent === true)
              this.showWarningSwal(
                "Very important!",
                "Add Accounts or check the account name has been chosen correctly"
              );
            else this.showWarningSwal("Very important!", "Add Accounts");
          } else {
            const confirm = await this.showConfirmSwal();
            if (confirm.isConfirmed) {
              this.addPreloader()
              const data = await ClientsOptionsServices.deleteAccountsAc({
                iduser: this.userId,
                account: this.clientAccount,
                idround: this.idround,
                dt: this.dataToSend,
              });
              if (data.status === 200) {
                this.$emit("close");
                // this.$emit("reloadT", 4);
                this.updateRefreshReport(4);
                this.showSuccessSwal();
              }
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
.hr-b {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 5px solid #ebe9f1 !important;
}
</style>
