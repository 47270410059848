var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('span',[_vm._v("PUBLIC RECORDS")])]),_c('b-col',[_c('span',[_vm._v("DATE")])]),_c('b-col',[_c('span',[_vm._v("COURT CASE #")])]),_c('b-col',{staticClass:"text-center"},[_c('span',[_vm._v("Trans"),_c('span',{staticStyle:{"color":"#4d917a"}},[_vm._v("Union")])])]),_c('b-col',{staticClass:"text-center"},[_c('span',{staticStyle:{"color":"#0566b7","font-weight":"bold"}},[_vm._v("Experian")])]),_c('b-col',{staticClass:"text-center"},[_c('span',{staticStyle:{"color":"#822a3a","font-weight":"bold"}},[_vm._v("EQUIFAX")])]),(_vm.type === 1)?_c('b-col',[_c('span',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("REASON")]),_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"PlusCircleIcon","size":"20"},on:{"click":function($event){return _vm.addAccountCreditor(2)}}})],1)]):_vm._e(),(_vm.type === 1)?_c('b-col',[_c('span',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("INSTRUCTION")]),_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"PlusCircleIcon","size":"20"},on:{"click":function($event){return _vm.addAccountCreditor(3)}}})],1)]):_vm._e()],1),_c('validation-observer',{ref:"form"},[_c('b-row',_vm._l((_vm.counts),function(item,index){return _c('b-col',{key:index,staticClass:"mt-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',[_c('b-form-input',{model:{value:(item.publicRecord),callback:function ($$v) {_vm.$set(item, "publicRecord", $$v)},expression:"item.publicRecord"}})],1),_c('b-col',[_c('b-form-datepicker',{attrs:{"placeholder":_vm.type == 1 ? '' : 'No date selected',"date-format-options":{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1),_c('b-col',[_c('b-form-input',{model:{value:(item.courtCase),callback:function ($$v) {_vm.$set(item, "courtCase", $$v)},expression:"item.courtCase"}})],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('validation-provider',{attrs:{"name":("Trans union check " + index),"rules":(item.publicRecord !== '' ||
                  item.courtCase != '' ||
                  item.date != '') &&
                item.expeCheck === false &&
                item.equiCheck === false
                  ? 'truthy'
                  : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"check-bigger d-flex justify-content-center",attrs:{"state":errors[0] ? false : null},model:{value:(item.tranCheck),callback:function ($$v) {_vm.$set(item, "tranCheck", $$v)},expression:"item.tranCheck"}})]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('validation-provider',{attrs:{"name":("Experian check " + index),"rules":(item.publicRecord !== '' ||
                  item.courtCase != '' ||
                  item.date != '') &&
                item.tranCheck === false &&
                item.equiCheck === false
                  ? 'truthy'
                  : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"check-bigger d-flex justify-content-center",attrs:{"state":errors[0] ? false : null},model:{value:(item.expeCheck),callback:function ($$v) {_vm.$set(item, "expeCheck", $$v)},expression:"item.expeCheck"}})]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('validation-provider',{attrs:{"name":("Equifax check " + index),"rules":(item.publicRecord !== '' ||
                  item.courtCase != '' ||
                  item.date != '') &&
                item.expeCheck === false &&
                item.tranCheck === false
                  ? 'truthy'
                  : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"check-bigger d-flex justify-content-center",attrs:{"state":errors[0] ? false : null},model:{value:(item.equiCheck),callback:function ($$v) {_vm.$set(item, "equiCheck", $$v)},expression:"item.equiCheck"}})]}}],null,true)})],1),(_vm.type === 1)?_c('b-col',[_c('b-form-select',{attrs:{"options":_vm.reasonsLocal,"text-field":"value","value-field":"id"},model:{value:(item.reason),callback:function ($$v) {_vm.$set(item, "reason", $$v)},expression:"item.reason"}})],1):_vm._e(),(_vm.type === 1)?_c('b-col',[_c('b-form-select',{attrs:{"options":_vm.instructionsLocal,"text-field":"value","value-field":"id"},model:{value:(item.instruction),callback:function ($$v) {_vm.$set(item, "instruction", $$v)},expression:"item.instruction"}})],1):_vm._e()],1),(_vm.type !== 1)?_c('b-row',[_c('b-col'),_c('b-col'),_c('b-col'),_c('b-col',[_c('validation-provider',{attrs:{"name":("Union status " + index),"rules":item.tranCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.statusesLocal,"text-field":"value","value-field":"id"},model:{value:(item.statusTrUnion),callback:function ($$v) {_vm.$set(item, "statusTrUnion", $$v)},expression:"item.statusTrUnion"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Status "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":("Experian status " + index),"rules":item.expeCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.statusesLocal,"text-field":"value","value-field":"id"},model:{value:(item.statusExperian),callback:function ($$v) {_vm.$set(item, "statusExperian", $$v)},expression:"item.statusExperian"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Status "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":("Equifax status " + index),"rules":item.equiCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.statusesLocal,"text-field":"value","value-field":"id"},model:{value:(item.statusEquifax),callback:function ($$v) {_vm.$set(item, "statusEquifax", $$v)},expression:"item.statusEquifax"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Status "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}}),_c('b-col',{attrs:{"cols":"2"}}),_c('b-col',{attrs:{"cols":"2"}}),_c('b-col',[(
                (item.publicRecord !== '' ||
                  item.courtCase !== '' ||
                  item.date !== '') &&
                item.tranCheck === false &&
                item.equiCheck === false &&
                item.expeCheck === false
              )?_c('span',{staticClass:"text-warning"},[_vm._v("* Select at least one")]):_vm._e()])],1)],1)}),1)],1),(_vm.modalCreditorController)?_c('modal-add-account-creditor',{attrs:{"typeinsert":_vm.typeInsert},on:{"close":_vm.closeModalCreditorController,"reload":_vm.reloadEmit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }