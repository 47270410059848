<template>
  <b-modal
    v-model="onControl"
    title="Edit Personal Information"
    title-tag="h3"
    scrollable
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Edit Personal Information
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <span v-if="piItemLocal.bureau_id === 1"
        ><span>Trans<span style="color: #4d917a">Union</span></span></span
      >
      <span v-else-if="piItemLocal.bureau_id === 2"
        ><span style="color: #0566b7" class="font-weight-bolder"
          >Experian</span
        ></span
      >
      <span v-else-if="piItemLocal.bureau_id === 3"
        ><span style="color: #822a3a" class="font-weight-bolder"
          >EQUIFAX</span
        ></span
      >
      <validation-provider
        v-slot="{ errors }"
        name="account_type"
        rules="required"
      >
        <b-form-input v-model="piItemLocal.content" class="mt-1" />
        <span v-if="errors[0]" class="text-danger">
          This field {{ errors[0] }}</span
        >
      </validation-provider>
      <b-form-select
        v-model="piItemLocal.status"
        class="mt-1"
        :options="statuses"
        text-field="value"
        value-field="id"
      />
    </validation-observer>
    <template #modal-footer>
      <button-save @click="saveEdit" />
    </template>
  </b-modal>
</template>

<script>
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";

export default {
  components: {
    ButtonSave,
  },
  props: {
    piItem: null,
    userId: null,
  },
  data() {
    return {
      onControl: false,
      piItemLocal: {},
      statuses: [],
    };
  },
  async created() {
    this.piItemLocal = { ...this.piItem };
    this.onControl = true;
    await this.getStatuses();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async saveEdit() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          this.addPreloader();
          const data = await ClientsOptionsServices.editPersonalInformation({
            id: this.piItemLocal.id,
            status: this.piItemLocal.status,
            content: this.piItemLocal.content,
            iduser: this.userId,
            instruction: null,
            reason: null,
          });
          if (data.status === 200) {
            this.close();
            this.$emit("reload", 1);
            this.removePreloader();
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },

    async getStatuses() {
      try {
        const data = await ClientsOptionsServices.getStatusLetters();
        if (data.status === 200) {
          this.statuses = data.data;
        }
        this.statuses = this.statuses.filter((status) => status.id !== 1);
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
</style>
