<template>
  <b-modal
    v-model="onControl"
    :title="title"
    modal-class="modal-primary"
    title-tag="h3"
    @hidden="close"
  >
    <validation-observer ref="form">
      <validation-provider
        v-slot="{ errors }"
        name="label"
        rules="required"
      >
        <b-form-group
          :label="label"
        >
          <b-form-input v-model="accountName" />
        </b-form-group>
        <span
          v-if="errors[0]"
          class="text-danger"
        >
          This field {{ errors[0] }}</span>
      </validation-provider>
    </validation-observer>
    <template #modal-footer="{cancel}">
      <button-cancel @click="cancel"/>
      <button-save @click="saveAccount"/>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";

export default {
  components: {
    ButtonSave,
    ButtonCancel,
  },
  props: {
    typeinsert: null,
  },
  data() {
    return {
      onControl: false,
      title: '',
      label: '',
      accountName: '',
    }
  },
  async created() {
    this.onControl = true
    switch (this.typeinsert) {
      case 1: this.title = 'ADD ACCOUNT NAME'; this.label = 'ACCOUNT NAME'; break
      case 2: this.title = 'ADD REASON'; this.label = 'REASON'; break
      case 3: this.title = 'ADD INSTRUCTION '; this.label = 'INSTRUCTION'; break
      case 4: this.title = 'ADD ACCOUNT TYPE '; this.label = 'ACCOUNT TYPE'; break
      case 5: this.title = 'ADD PAYMENT STATUS'; this.label = 'PAYMENT STATUS'; break
      default: break
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async saveAccount() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            const data = await ClientsOptionsServices.addAccountCreditor({
              accountName: this.accountName,
              typeinsert: this.typeinsert,
            })
            if (data.status === 200) {
              if (data.data[0].result === 1) {
                this.showSuccessSwal()
                this.$emit('reload', this.typeinsert)
                this.close()
              }
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
