var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr',{staticClass:"hr-b"}),_c('validation-observer',{ref:"form"},_vm._l((_vm.counts),function(item,index){return _c('div',{key:index},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('span',[_vm._v("Trans"),_c('span',{staticStyle:{"color":"#4d917a"}},[_vm._v("Union")])])]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('span',{staticClass:"font-weight-bolder",staticStyle:{"color":"#0566b7"}},[_vm._v("Experian")])]),_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c('span',{staticClass:"font-weight-bolder",staticStyle:{"color":"#822a3a"}},[_vm._v("EQUIFAX")])]),_c('b-col',[_c('span',[_vm._v("MODE")])])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"1"}},[_c('validation-provider',{attrs:{"name":("Trans union check " + index),"rules":item.expeCheck === false &&
              item.equiCheck === false &&
              index === 0
                ? 'truthy'
                : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"check-bigger d-flex justify-content-center",attrs:{"state":errors[0] ? false : null},model:{value:(item.tranCheck),callback:function ($$v) {_vm.$set(item, "tranCheck", $$v)},expression:"item.tranCheck"}})]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"1"}},[_c('validation-provider',{attrs:{"name":("Experian check " + index),"rules":item.tranCheck === false &&
              item.equiCheck === false &&
              index === 0
                ? 'truthy'
                : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"check-bigger d-flex justify-content-center",attrs:{"state":errors[0] ? false : null},model:{value:(item.expeCheck),callback:function ($$v) {_vm.$set(item, "expeCheck", $$v)},expression:"item.expeCheck"}})]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"1"}},[_c('validation-provider',{attrs:{"name":("Equifax check " + index),"rules":item.expeCheck === false &&
              item.tranCheck === false &&
              index === 0
                ? 'truthy'
                : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"check-bigger d-flex justify-content-center",attrs:{"state":errors[0] ? false : null},model:{value:(item.equiCheck),callback:function ($$v) {_vm.$set(item, "equiCheck", $$v)},expression:"item.equiCheck"}})]}}],null,true)})],1),_c('b-col',[_c('b-form-group',[_c('b-form-radio',{attrs:{"value":1},model:{value:(item.modeRadio),callback:function ($$v) {_vm.$set(item, "modeRadio", $$v)},expression:"item.modeRadio"}},[_vm._v(" Same for all bureaus ")]),_c('b-form-radio',{staticStyle:{"margin-top":"0.3rem"},attrs:{"value":2},model:{value:(item.modeRadio),callback:function ($$v) {_vm.$set(item, "modeRadio", $$v)},expression:"item.modeRadio"}},[_vm._v(" Different for each bureau ")])],1)],1)],1),_c('b-row',[_c('b-col',[(
              item.expeCheck === false &&
              item.tranCheck === false &&
              item.equiCheck === false &&
              _vm.result === false &&
              index === 0
            )?_c('span',{staticClass:"text-danger"},[_vm._v("*Select at least one")]):_vm._e()])],1),(
          item.modeRadio === 1 ||
          (item.modeRadio === 2 &&
            (item.tranCheck === true ||
              item.expeCheck === true ||
              item.equiCheck === true))
        )?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v("ACCOUNT TYPE")]),_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"PlusCircleIcon","size":"15"},on:{"click":function($event){return _vm.addAccountCreditor(4)}}})],1),(_vm.type !== 1)?_c('b-col',[_c('span',[_vm._v("STATUS")])]):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v("ACCOUNT NAME")]),_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"PlusCircleIcon","size":"15"},on:{"click":function($event){return _vm.addAccountCreditor(1)}}})],1),_c('b-col',[_c('span',[_vm._v("ACCOUNT NUMBER")])]),_c('b-col',[_c('span',[_vm._v("BALANCE")])]),(_vm.type === 1)?_c('b-col',[_c('span',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("REASON")]),_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"PlusCircleIcon","size":"15"},on:{"click":function($event){return _vm.addAccountCreditor(2)}}})],1)]):_vm._e(),(_vm.type === 1)?_c('b-col',[_c('span',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("INSTRUCTION")]),_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"PlusCircleIcon","size":"20"},on:{"click":function($event){return _vm.addAccountCreditor(3)}}})],1)]):_vm._e(),_c('b-col',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v("PAYMENT STATUS")]),_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"size":"15","icon":"PlusCircleIcon"},on:{"click":function($event){return _vm.addAccountCreditor(5)}}})],1),_c('b-col',[_c('span',[_vm._v("CREDIT LIMIT")])]),_c('b-col',[_c('span',[_vm._v("MONTHLY PAYMENT")])])],1):_vm._e(),(item.modeRadio === 1)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('validation-provider',{attrs:{"name":("Type gen " + index),"rules":item.expeCheck === true ||
              item.equiCheck === true ||
              item.tranCheck === true
                ? 'required'
                : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.accounts,"text-field":"value","value-field":"id"},model:{value:(item.accTypeSelect),callback:function ($$v) {_vm.$set(item, "accTypeSelect", $$v)},expression:"item.accTypeSelect"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(_vm.type !== 1)?_c('b-col',[_c('validation-provider',{attrs:{"name":("Status gen " + index),"rules":item.expeCheck === true ||
              item.equiCheck === true ||
              item.tranCheck === true
                ? 'required'
                : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [(_vm.type !== 1)?_c('b-form-select',{attrs:{"options":_vm.statusesLocal,"text-field":"value","value-field":"id"},model:{value:(item.statusSelect),callback:function ($$v) {_vm.$set(item, "statusSelect", $$v)},expression:"item.statusSelect"}}):_vm._e(),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('b-col',[_c('validation-provider',{attrs:{"name":("Acc name gen " + index),"rules":item.expeCheck === true ||
              item.equiCheck === true ||
              item.tranCheck === true
                ? 'required'
                : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.optionsToSuggestGen,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
                id: 'name-account-general',
                class: 'form-control',
                placeholder: 'Select',
              }},on:{"input":function($event){return _vm.searchOnInput(item.accNameShow, 1)},"selected":function($event){return _vm.selectHandler($event, item, 'accName')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")])]}}],null,true),model:{value:(item.accNameShow),callback:function ($$v) {_vm.$set(item, "accNameShow", $$v)},expression:"item.accNameShow"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":("Acc number gen " + index),"rules":item.expeCheck === true ||
              item.equiCheck === true ||
              item.tranCheck === true
                ? 'required'
                : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{model:{value:(item.accNumber),callback:function ($$v) {_vm.$set(item, "accNumber", $$v)},expression:"item.accNumber"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('money',_vm._b({staticClass:"form-control",model:{value:(item.balanceMoney),callback:function ($$v) {_vm.$set(item, "balanceMoney", $$v)},expression:"item.balanceMoney"}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
              masked: false,
            },false))],1),(_vm.type == 1)?_c('b-col',[_c('b-form-select',{attrs:{"options":_vm.reasonsLocal,"text-field":"value","value-field":"id"},model:{value:(item.reason),callback:function ($$v) {_vm.$set(item, "reason", $$v)},expression:"item.reason"}})],1):_vm._e(),(_vm.type == 1)?_c('b-col',[_c('b-form-select',{attrs:{"options":_vm.instructionsLocal,"text-field":"value","value-field":"id"},model:{value:(item.instruction),callback:function ($$v) {_vm.$set(item, "instruction", $$v)},expression:"item.instruction"}})],1):_vm._e(),_c('b-col',[_c('b-form-select',{attrs:{"options":_vm.paymentsStatusFrom,"text-field":"name","value-field":"id"},model:{value:(item.paymentStatSelect),callback:function ($$v) {_vm.$set(item, "paymentStatSelect", $$v)},expression:"item.paymentStatSelect"}})],1),_c('b-col',[_c('money',_vm._b({staticClass:"form-control",model:{value:(item.creditMoney),callback:function ($$v) {_vm.$set(item, "creditMoney", $$v)},expression:"item.creditMoney"}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
              masked: false,
            },false))],1),_c('b-col',[_c('money',_vm._b({staticClass:"form-control",model:{value:(item.monthlyPayMoney),callback:function ($$v) {_vm.$set(item, "monthlyPayMoney", $$v)},expression:"item.monthlyPayMoney"}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
              masked: false,
            },false))],1)],1):_vm._e(),(item.modeRadio === 2 && item.tranCheck === true)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('validation-provider',{attrs:{"name":("Tr type account " + index),"rules":item.tranCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.accounts,"text-field":"value","value-field":"id"},model:{value:(item.accTypeSelectTran),callback:function ($$v) {_vm.$set(item, "accTypeSelectTran", $$v)},expression:"item.accTypeSelectTran"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(_vm.type !== 1)?_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('validation-provider',{attrs:{"name":("Tr status " + index),"rules":item.tranCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(_vm.type !== 1)?_c('b-form-select',{attrs:{"options":_vm.statusesLocal,"text-field":"value","value-field":"id"},model:{value:(item.statusSelectTran),callback:function ($$v) {_vm.$set(item, "statusSelectTran", $$v)},expression:"item.statusSelectTran"}}):_vm._e(),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('validation-provider',{attrs:{"name":("Tr acc name " + index),"rules":item.tranCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.optionsToSuggestTran,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
                id: 'name-account-trans',
                class: 'form-control',
                placeholder: 'Select',
              }},on:{"input":function($event){return _vm.searchOnInput(item.accNameTranShow, 2)},"selected":function($event){return _vm.selectHandler($event, item, 'accNameTran')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")])]}}],null,true),model:{value:(item.accNameTranShow),callback:function ($$v) {_vm.$set(item, "accNameTranShow", $$v)},expression:"item.accNameTranShow"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('validation-provider',{attrs:{"name":("Acc Number tr " + index),"rules":item.tranCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{model:{value:(item.accNumberTran),callback:function ($$v) {_vm.$set(item, "accNumberTran", $$v)},expression:"item.accNumberTran"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('validation-provider',{attrs:{"name":("Balance tran " + index),"rules":item.tranCheck === true ? 'validate-amount' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",model:{value:(item.balanceMoneyTran),callback:function ($$v) {_vm.$set(item, "balanceMoneyTran", $$v)},expression:"item.balanceMoneyTran"}},'money',{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              },false)),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(_vm.type == 1)?_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('b-form-select',{attrs:{"options":_vm.reasonsLocal,"text-field":"value","value-field":"id"},model:{value:(item.reasonTran),callback:function ($$v) {_vm.$set(item, "reasonTran", $$v)},expression:"item.reasonTran"}})],1):_vm._e(),(_vm.type == 1)?_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('b-form-select',{attrs:{"options":_vm.instructionsLocal,"text-field":"value","value-field":"id"},model:{value:(item.instructionTran),callback:function ($$v) {_vm.$set(item, "instructionTran", $$v)},expression:"item.instructionTran"}})],1):_vm._e(),_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('b-form-select',{attrs:{"options":_vm.paymentsStatusFrom,"text-field":"name","value-field":"id"},model:{value:(item.paymentStatSelectTran),callback:function ($$v) {_vm.$set(item, "paymentStatSelectTran", $$v)},expression:"item.paymentStatSelectTran"}})],1),_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('money',_vm._b({staticClass:"form-control",model:{value:(item.creditMoneyTran),callback:function ($$v) {_vm.$set(item, "creditMoneyTran", $$v)},expression:"item.creditMoneyTran"}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
              masked: false,
            },false))],1),_c('b-col',[_c('span',[_vm._v("Transunion")]),_c('money',_vm._b({staticClass:"form-control",model:{value:(item.monthlyPayMoneyTran),callback:function ($$v) {_vm.$set(item, "monthlyPayMoneyTran", $$v)},expression:"item.monthlyPayMoneyTran"}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
              masked: false,
            },false))],1)],1):_vm._e(),(item.modeRadio === 2 && item.expeCheck === true)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('span',[_vm._v("Experian")]),_c('validation-provider',{attrs:{"name":("Experian type " + index),"rules":item.expeCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.accounts,"text-field":"value","value-field":"id"},model:{value:(item.accTypeSelectExpe),callback:function ($$v) {_vm.$set(item, "accTypeSelectExpe", $$v)},expression:"item.accTypeSelectExpe"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(_vm.type !== 1)?_c('b-col',[_c('span',[_vm._v("Experian")]),_c('validation-provider',{attrs:{"name":("Exp status " + index),"rules":item.expeCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(_vm.type !== 1)?_c('b-form-select',{attrs:{"options":_vm.statusesLocal,"text-field":"value","value-field":"id"},model:{value:(item.statusSelectExpe),callback:function ($$v) {_vm.$set(item, "statusSelectExpe", $$v)},expression:"item.statusSelectExpe"}}):_vm._e(),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('b-col',[_c('span',[_vm._v("Experian")]),_c('validation-provider',{attrs:{"name":("Experian acc name " + index),"rules":item.expeCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.optionsToSuggestExp,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
                id: 'name-account-experian',
                class: 'form-control',
                placeholder: 'Select',
              }},on:{"input":function($event){return _vm.searchOnInput(item.accNameExpeShow, 3)},"selected":function($event){return _vm.selectHandler($event, item, 'accNameExpe')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")])]}}],null,true),model:{value:(item.accNameExpeShow),callback:function ($$v) {_vm.$set(item, "accNameExpeShow", $$v)},expression:"item.accNameExpeShow"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('span',[_vm._v("Experian")]),_c('validation-provider',{attrs:{"name":("Experian acc number " + index),"rules":item.expeCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{model:{value:(item.accNumberExpe),callback:function ($$v) {_vm.$set(item, "accNumberExpe", $$v)},expression:"item.accNumberExpe"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('span',[_vm._v("Experian")]),_c('validation-provider',{attrs:{"name":("Balance exp " + index),"rules":item.expeCheck === true ? 'validate-amount' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",model:{value:(item.balanceMoneyExpe),callback:function ($$v) {_vm.$set(item, "balanceMoneyExpe", $$v)},expression:"item.balanceMoneyExpe"}},'money',{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              },false)),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(_vm.type == 1)?_c('b-col',[_c('span',[_vm._v("Experian")]),_c('b-form-select',{attrs:{"options":_vm.reasonsLocal,"text-field":"value","value-field":"id"},model:{value:(item.reasonExpe),callback:function ($$v) {_vm.$set(item, "reasonExpe", $$v)},expression:"item.reasonExpe"}})],1):_vm._e(),(_vm.type == 1)?_c('b-col',[_c('span',[_vm._v("Experian")]),_c('b-form-select',{attrs:{"options":_vm.instructionsLocal,"text-field":"value","value-field":"id"},model:{value:(item.instructionExpe),callback:function ($$v) {_vm.$set(item, "instructionExpe", $$v)},expression:"item.instructionExpe"}})],1):_vm._e(),_c('b-col',[_c('span',[_vm._v("Experian")]),_c('b-form-select',{attrs:{"options":_vm.paymentsStatusFrom,"text-field":"name","value-field":"id"},model:{value:(item.paymentStatSelectExpe),callback:function ($$v) {_vm.$set(item, "paymentStatSelectExpe", $$v)},expression:"item.paymentStatSelectExpe"}})],1),_c('b-col',[_c('span',[_vm._v("Experian")]),_c('money',_vm._b({staticClass:"form-control",model:{value:(item.creditMoneyExpe),callback:function ($$v) {_vm.$set(item, "creditMoneyExpe", $$v)},expression:"item.creditMoneyExpe"}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
              masked: false,
            },false))],1),_c('b-col',[_c('span',[_vm._v("Experian")]),_c('money',_vm._b({staticClass:"form-control",model:{value:(item.monthlyPayMoneyExpe),callback:function ($$v) {_vm.$set(item, "monthlyPayMoneyExpe", $$v)},expression:"item.monthlyPayMoneyExpe"}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
              masked: false,
            },false))],1)],1):_vm._e(),(item.modeRadio === 2 && item.equiCheck === true)?_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('validation-provider',{attrs:{"name":("Equifax type " + index),"rules":item.equiCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.accounts,"text-field":"value","value-field":"id"},model:{value:(item.accTypeSelectEqui),callback:function ($$v) {_vm.$set(item, "accTypeSelectEqui", $$v)},expression:"item.accTypeSelectEqui"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(_vm.type !== 1)?_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('validation-provider',{attrs:{"name":("Equi status " + index),"rules":item.equiCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(_vm.type !== 1)?_c('b-form-select',{attrs:{"options":_vm.statusesLocal,"text-field":"value","value-field":"id"},model:{value:(item.statusSelectEqui),callback:function ($$v) {_vm.$set(item, "statusSelectEqui", $$v)},expression:"item.statusSelectEqui"}}):_vm._e(),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('validation-provider',{attrs:{"name":("Equifax acc name " + index),"rules":item.equiCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.optionsToSuggestEqui,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
                id: 'name-account-equifax',
                class: 'form-control',
                placeholder: 'Select',
              }},on:{"input":function($event){return _vm.searchOnInput(item.accNameEquiShow, 4)},"selected":function($event){return _vm.selectHandler($event, item, 'accNameEqui')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")])]}}],null,true),model:{value:(item.accNameEquiShow),callback:function ($$v) {_vm.$set(item, "accNameEquiShow", $$v)},expression:"item.accNameEquiShow"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('validation-provider',{attrs:{"name":("Equifax acc number " + index),"rules":item.equiCheck === true ? 'required' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{model:{value:(item.accNumberEqui),callback:function ($$v) {_vm.$set(item, "accNumberEqui", $$v)},expression:"item.accNumberEqui"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" This field "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('validation-provider',{attrs:{"name":("Balance equi " + index),"rules":item.tranCheck === true ? 'validate-amount' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",model:{value:(item.balanceMoneyEqui),callback:function ($$v) {_vm.$set(item, "balanceMoneyEqui", $$v)},expression:"item.balanceMoneyEqui"}},'money',{
                decimal: '.',
                thousands: ',',
                prefix: '$',
                precision: 2,
                masked: false,
              },false)),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(_vm.type == 1)?_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('b-form-select',{attrs:{"options":_vm.reasonsLocal,"text-field":"value","value-field":"id"},model:{value:(item.reasonEqui),callback:function ($$v) {_vm.$set(item, "reasonEqui", $$v)},expression:"item.reasonEqui"}})],1):_vm._e(),(_vm.type == 1)?_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('b-form-select',{attrs:{"options":_vm.instructionsLocal,"text-field":"value","value-field":"id"},model:{value:(item.instructionEqui),callback:function ($$v) {_vm.$set(item, "instructionEqui", $$v)},expression:"item.instructionEqui"}})],1):_vm._e(),_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('b-form-select',{attrs:{"options":_vm.paymentsStatusFrom,"text-field":"name","value-field":"id"},model:{value:(item.paymentStatSelectEqui),callback:function ($$v) {_vm.$set(item, "paymentStatSelectEqui", $$v)},expression:"item.paymentStatSelectEqui"}})],1),_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('money',_vm._b({staticClass:"form-control",model:{value:(item.creditMoneyEqui),callback:function ($$v) {_vm.$set(item, "creditMoneyEqui", $$v)},expression:"item.creditMoneyEqui"}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
              masked: false,
            },false))],1),_c('b-col',[_c('span',[_vm._v("Equifax")]),_c('money',_vm._b({staticClass:"form-control",model:{value:(item.monthlyPayMoneyEqui),callback:function ($$v) {_vm.$set(item, "monthlyPayMoneyEqui", $$v)},expression:"item.monthlyPayMoneyEqui"}},'money',{
              decimal: '.',
              thousands: ',',
              prefix: '$',
              precision: 2,
              masked: false,
            },false))],1)],1):_vm._e(),_c('hr',{staticClass:"hr-b"})],1)}),0),(_vm.modalCreditorController)?_c('modal-add-account-creditor',{attrs:{"typeinsert":_vm.typeInsert},on:{"close":_vm.closeModalCreditorController,"reload":_vm.reloadEmit}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }