<template>
  <b-modal
    v-model="onControl"
    title="ADD ACCOUNTS"
    title-tag="h3"
    modal-class="custom-modal-amg"
    size="caro"
    scrollable
    header-bg-variant="transparent"
    header-class="p-0"
    no-close-on-backdrop
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Add Personal Information
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <b-container fluid>
      <div class="d-flex justify-content-between pt-2">
        <div class="d-flex">
          <div class="info-container mr-2" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0 px-2"
              style="gap: 5px"
            >
              Account | {{ accountName }}
            </p>
          </div>
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0 px-2"
              style="gap: 5px"
            >
              Client | {{ nameClient }}
            </p>
          </div>
        </div>
        <div>
          <b-form-select
            v-model="category"
            :options="optionsCategories"
            class="px-2"
            @change="hideFooter = true"
            disabled
          />
        </div>
      </div>
      <b-row class="mt-2">
        <b-col cols="12">
          <transition name="fade">
            <personal-information-component
              v-if="category === 1 && statuses.length"
              ref="per-info"
              :type="0"
              :idround="null"
              :client-account="accountName"
              :statuses="statuses"
              :user-id="currentUser.user_id"
              :reasons="reasons"
              :instructions="instructions"
              @close="close"
              @reload="reloadOptions"
            />
          </transition>
          <transition name="fade">
            <inquiries-component
              v-if="category === 2"
              ref="inquiries"
              :statuses="statuses"
              :reasons="reasons"
              :instructions="instructions"
              :type="0"
              :idround="null"
              :client-account="accountName"
              :user-id="currentUser.user_id"
              @close="close"
              @reload="reloadOptions"
            />
          </transition>
          <transition name="fade">
            <public-records-component
              v-if="category === 3"
              ref="public-records"
              :statuses="statuses"
              :reasons="reasons"
              :instructions="instructions"
              :type="0"
              :idround="null"
              :client-account="accountName"
              :user-id="currentUser.user_id"
              @close="close"
              @reload="reloadOptions"
            />
          </transition>
          <transition name="fade">
            <accounts-component
              v-if="category === 4"
              ref="accounts-comp"
              :statuses="statuses"
              :reasons="reasons"
              :instructions="instructions"
              :user-id="currentUser.user_id"
              :type="0"
              :idround="null"
              :client-account="accountName"
              :accounts="accounts"
              :payments-status-from="paymentsStatus"
              @close="close"
              @reload="reloadOptions"
            />
          </transition>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <button-save @click="saveAccount" />
    </template>
  </b-modal>
</template>
<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import PersonalInformationComponent from "@/views/commons/components/clients/dashboard/options/report-module/components/PersonalInformationComponent.vue";
import PublicRecordsComponent from "@/views/commons/components/clients/dashboard/options/report-module/components/PublicRecordsComponent.vue";
import InquiriesComponent from "@/views/commons/components/clients/dashboard/options/report-module/components/InquiriesComponent.vue";
import AccountsComponent from "@/views/commons/components/clients/dashboard/options/report-module/components/AccountsComponent.vue";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ButtonSave,
    PersonalInformationComponent,
    PublicRecordsComponent,
    InquiriesComponent,
    AccountsComponent,
  },
  computed: {
    // eslint-disable-next-line consistent-return
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  props: {
    nameClient: null,
    accountName: null,
  },
  data() {
    return {
      statuses: [],
      onControl: false,
      category: 1,
      optionsCategories: [
        { value: 1, text: "PERSONAL INFORMATION" },
        // { value: 2, text: "INQUIRIES" },
        // { value: 3, text: "PUBLIC RECORDS" },
        // { value: 4, text: "ACCOUNTS" },
      ],
      showWord: false,
      hideFooter: false,
      reasons: [],
      instructions: [],
      accounts: [],
      paymentsStatus: [],
    };
  },
  async created() {
    this.onControl = true;
    await Promise.all([
      this.getStatuses(),
      this.getReasons(),
      this.getInstructions(),
      this.getAccountsLocal(),
      this.getPaymentsStatus(),
    ]);
  },
  methods: {
    close() {
      this.$emit("close");
    },

    async getStatuses() {
      try {
        const data = await ClientsOptionsServices.getStatusLetters();
        if (data.status === 200) {
          this.statuses = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async saveAccount() {
      if (this.category === 1) {
        await this.$refs["per-info"].saveData();
      } else if (this.category === 2) {
        await this.$refs.inquiries.saveData();
      } else if (this.category === 3) {
        await this.$refs["public-records"].saveData();
      } else if (this.category === 4) {
        await this.$refs["accounts-comp"].saveData();
      }
    },
    reloadTable(category) {
      this.$emit("reloadTable", category);
    },
    async getReasons() {
      try {
        const data = await ClientsOptionsServices.getReasons();
        if (data.status === 200) {
          this.reasons = data.data;
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async getInstructions() {
      try {
        const data = await ClientsOptionsServices.getInstructions();
        if (data.status === 200) {
          this.instructions = data.data;
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async reloadOptions(type) {
      if (type === 4) {
        await this.getAccountsLocal();
      } else if (type === 5) {
        await this.getPaymentsStatus();
      } else if (type === 2) {
        await this.getReasons();
      } else if (type === 3) {
        await this.getInstructions();
      }
    },
    async getAccountsLocal() {
      try {
        const data = await ClientsOptionsServices.getStatusAccountsLetter();
        if (data.status === 200) {
          this.accounts = data.data;
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async getPaymentsStatus() {
      try {
        const data = await ClientsOptionsServices.getPaymentsStatus();
        if (data.status === 200) {
          this.paymentsStatus = data.data;
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.info-container {
  border: 1px solid;
  border-radius: 10px;
  padding: 5px;
}
</style>
